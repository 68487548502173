#red-23 {
  animation: gored 1.5s infinite;
}

#red-24 {
  animation: gored 2s infinite;
}

#red-25 {
  animation: gored 1.5s infinite;
}

#green-23 {
  animation: gogreen 2s linear infinite;
}

#green-24 {
  animation: gogreen 1.5s linear infinite;
}

#green-25 {
  animation: gogreen 2s linear infinite;
}

#green-26 {
  animation: gogreen 1.5s linear infinite;
}

#green-27 {
  animation: gogreen 1.5s linear infinite;
}

@keyframes gogreen {
  0% {
    fill: greenyellow;
  }
  50% {
    fill: black;
  }
  100% {
    fill: greenyellow;
  }
}

@keyframes gored {
  0% {
    fill: hsl(10, 80%, 50%);
  }
  50% {
    fill: black;
  }
  100% {
    fill: hsl(10, 80%, 50%);
  }
}

.go-green:hover {
  fill: green;
}
